<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      :title="$t('common2[0]')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div class="serviceHeader">{{$t('service2[0]')}}</div>
      <div class="listPanel">
        <van-cell  v-for="item in servicelist"
                   :border="false"
                   size="large"
                   :title="item.title"
                   @click="$router.push(`/article/service/${item.id}`)"
                   center
                   is-link
        />
      </div>
      <div class="serviceHeader">{{$t('service2[1]')}}</div>
      <div class="listPanel">
        <van-cell  v-for="item in servicelist2"
                   :border="false"
                   size="large"
                   :title="item.title"
                   @click="$router.push(`/article/service/${item.id}`)"
                   center
                   is-link
        />
      </div>
      <div style="line-height: 35px;margin: 10px auto;text-align: center">
        <div style="margin: 10px;text-align: left">{{$t('common7[0]')}}</div>
      <van-button color="#DAA78A" size="large" style="margin: 0px auto;width: 95%;text-align: center"
                  @click="gotoUrl"
      ><span style="color:#FFFEFF">{{$t('common7[1]')}}</span>
      </van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Service',
  components: {

  },
  props: {},
  data() {
    return {
      servicelist:[],
      servicelist2:[]
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    
  },
  mounted() {
    var servicelist0 = this.InitData.serviceList;
    if (servicelist0.length>3){
      for (var i=0;i<servicelist0.length-3;i++){
        this.servicelist.push(servicelist0[i])
      }
      for (var i=servicelist0.length-3;i<servicelist0.length;i++){
        this.servicelist2.push(servicelist0[i])
      }
    }else{
      this.servicelist = servicelist0;
    }
  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
    gotoUrl(){
      window.location.href = this.InitData.setting.service_url2;
    },
  }
}
</script>
<style scoped>
  .PageBox {
    color: #709C9D;
    background-color: #0F4F4E;
  }

  .serviceHeader{
    width: 90%;
    font-size: 18px;
    margin: 0 auto;
    margin-top: 10px;
  }
  .van-nav-bar {
    background-color: #0F4F4E;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    font-size: 20px;
    color:#FFFEFF;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#FFFEFF;
    font-weight: bold;
  }
  .ScrollBox{
    background-color: #0F4F4E;
    padding-left: 5px;
  }

  .listPanel{
    margin: 10px;
    border-radius: 5px;
    background-color: #337F7B;
    padding: 5px;
  }
  .van-cell {
    padding: 17px 0;
    padding-left: 20px;
    background-color: #337F7B;
  }

  .van-cell__title {
    color: #e7e7e7;
  }
  .van-cell>>>.van-icon{
    font-size: 20px;
    color: #89BFC1;
  }
</style>